import { isEmpty } from "lodash";
import React, { useState, useContext, useRef, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import AuthProvider from "../context/AuthContext";
import { apiGet } from "../Utils/apiFetch";
import apiPath from "../Utils/apiPath";
import Aboutus from "../components/staticContent/Aboutus";
import GeneralTerms from "../components/staticContent/GeneralTerms";
import Kyc from "../components/staticContent/Kyc";
import PrivacyPolicy from "../components/staticContent/PrivacyPolicy";
import RulesRegulation from "../components/staticContent/RulesRegulation";
import ResponsibleGaming from "../components/staticContent/ResponsibleGaming";
import { AiFillEye,AiFillEyeInvisible } from "react-icons/ai";
function Login(props) {
  let { loginUser, isLoged } = useContext(AuthProvider);
  const {
    register: register2,
    handleSubmit: handleSubmit2,
    formState: { errors: errors2 },
    unregister,
    reset: reset2,
  } = useForm({
    defaultValues: {
      username: "",
      password: "",
      uniqueId: Math.random() * 10000,
    },
  });

  const [passwordType, setPasswordType] = useState("password");
  const togglePassword =(e)=>{
    e.preventDefault()
    if(passwordType==="password")
    {
     setPasswordType("text")
     return;
    }
    setPasswordType("password")
  }
  const [getValidateCode, setValidateCode] = useState("");
  let { open, setOpen, content, slug, setSlug, handleContentToggle } =
    useContext(AuthProvider);

  const [data, setData] = useState({});
  const onSubmit2 = (data) => {
    if (parseInt(data.validateCode) === parseInt(getValidateCode)) {
      loginUser(data);
    }
  };
  const canvasRef = useRef(null);
  const changeCode = () => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    context.font = "bold 120px sans-serif";
    const code = Math.floor(1000 + Math.random() * 9000);
    context.clearRect(0, 0, canvas.width, canvas.height);
    context.fillText(code, 0, 130);
    setValidateCode(code);
  };

  const getSetting = async (event) => {
    try {
      const { status, data: response_users } = await apiGet(apiPath.getSetting);
      if (status === 200) {
        if (response_users.success) {
          setData(response_users.results);
        }
      }
    } catch (err) {}
  };
  useEffect(() => {
    changeCode();
    getSetting();
  }, []);
  return (
    <div>
      <div className="login_main" style={{ height: "100vh" }}>
        <header className="login-head">
          <Link to="/Duserhome" className="close"></Link>
          <h1 style={{ backgroundSize: "auto 100%" }}>bazi365</h1>

          <div id="powerWrap" className="betfair-wrap">
            <p>Powered by</p>
            <span></span>
          </div>
        </header>
        <Form onSubmit={handleSubmit2(onSubmit2)}>
          <dl className="form-login">
            <Form.Group
              style={{ marginBottom: "10px" }}
              id="loginNameErrorClass"
            >
              <Form.Control
                type="text"
                autocomplete="off"
                // id="loginName"
                className={errors2.username ? "is-invalid" : ""}
                {...register2("username", {
                  required: "Please enter Username",
                })}
                placeholder="Username"
              />
              {errors2.username && errors2?.username?.message && (
                <label className="invalid-feedback text-left">
                  {errors2.username.message}
                </label>
              )}
            </Form.Group>
            <Form.Group
              style={{ marginBottom: "10px"}}
              id="passwordErrorClass"
            >
              <div style={{ position:'relative'}}>
              <Form.Control
                type={passwordType ?passwordType:"password"}
                autoComplete="off"
                // id="password"
                className={errors2.password ? "is-invalid" : ""}
                {...register2("password", {
                  required: "Please enter password",
                })}
                data-role="none"
                placeholder="Password"
              />
              <div className="input-group-btn" style={{position:'absolute', right:'0', top:'0', bottom:'0px'}}>
                     <span className="btn btn-outline-primary" onClick={togglePassword} style={{backgroundColor:'transparent',backgroundImage: `none`, border:'0',padding:'8px 6px', paddingTop:'3px', height:'72%',fontSize:`6.5vw`,lineHeight: `1.8`}}>
                     { passwordType==="password"?<AiFillEyeInvisible/> :  <AiFillEye/>}
              </span>
              </div>
              </div>
              {errors2.password && errors2.password.message && (
                <label className="invalid-feedback text-left">
                  {errors2.password.message}
                </label>
              )}
            </Form.Group>
            <dd id="validCodeErrorClass" style={{ display: "block" }}>
              <input
                type="number"
                keyboardType="numeric"
                autocomplete="off"
                maxLength="4"
                className={errors2.validateCode ? "is-invalid" : ""}
                {...register2("validateCode", {
                  required: "Please enter validate code",
                  validate: {
                    validate: (value) =>
                      parseInt(value) === parseInt(getValidateCode) ||
                      "Invalid validate code",
                  },
                })}
                onChange={(e) => {
                  if (e.target.value.length == 4) {
                    e.target.blur();
                    unregister("validateCode", { keepValue: true });
                  }
                }}
                // onChange={(e) => {
                //   if (e.target.value.length <= 4) {
                //     setCode(false);
                //   } else {
                //     setCode(true);
                //   }
                // }}
                id="validCode"
                placeholder="Validation Code"
                // onClick={(e) => e.target.blur()}
                // onKeyDown={(e) => e.target.blur()}
              />
              {/* <img
                id="authenticateImage"
                src="assets/images/verifycode.jpg"
                alt=""
              /> */}
              <canvas
                ref={canvasRef}
                onClick={changeCode}
                className="inputcanvas"
                id="authenticateImage"
              />

              {errors2.validateCode && errors2.validateCode.message && (
                <label className="invalid-feedback text-left">
                  {errors2.validateCode.message}
                </label>
              )}
            </dd>
            {/* <dd>
              <input name="valid" type="hidden" id="valid" />
            </dd> */}
            <dd>
              <Button type="submit" className="btn-send w-100" id="loginBtn">
                Login
              </Button>
            </dd>
            {isLoged == "failed" && (
              <dd id="errorMsg" class="state text-white">
                Login name or password is invalid! Please try again.
              </dd>
            )}
          </dl>
        </Form>

        {/* <h3 className="note-website">
          Note - This Website is not for Indian Territory.
        </h3> */}

        <ul className="policy-link">
          <li>
            <Link
              to="javascript:void(0)"
              onClick={() => {
                handleContentToggle();
                setSlug("about");
              }}
            >
              About us
            </Link>
          </li>

          <li>
            <Link
              to="javascript:void(0)"
              onClick={() => {
                handleContentToggle();
                setSlug("term");
              }}
            >
              Terms and Conditions
            </Link>
          </li>

          <li>
            <Link
              to="javascript:void(0)"
              onClick={() => {
                handleContentToggle();
                setSlug("kyc");
              }}
            >
              KYC
            </Link>
          </li>

          <li>
            <Link
              to="javascript:void(0)"
              onClick={() => {
                handleContentToggle();
                setSlug("privacy");
              }}
            >
              Privacy Policy
            </Link>
          </li>

          <li>
            <Link
              to="javascript:void(0)"
              onClick={() => {
                handleContentToggle();
                setSlug("rules");
              }}
            >
              Rules and Regulations
            </Link>
          </li>

          <li>
            <Link
              to="javascript:void(0)"
              onClick={() => {
                handleContentToggle();
                setSlug("responsible");
              }}
            >
              Responsible Gaming
            </Link>
          </li>
          {/* <li>
              <a href="#">About Us</a>
            </li>
            <li>
              <a href="#">Self-Exclusion Policy</a>
            </li>
            <li>
              <a href="#">Underage Policy</a>
            </li> */}
        </ul>

        {/* static-content */}

        {content && (
          <div className="frame-wrap">
            <div className="news-wrap news-wrap-frame">
              {slug === "about" && <Aboutus />}
              {slug === "term" && <GeneralTerms />}
              {slug === "kyc" && <Kyc />}
              {slug === "privacy" && <PrivacyPolicy />}
              {slug === "rules" && <RulesRegulation />}
              {slug === "responsible" && <ResponsibleGaming />}

              <footer>
                <Link
                  to="#"
                  className="btn-send"
                  onClick={() => {
                    handleContentToggle();
                    setSlug("");
                  }}
                >
                  CLOSE
                </Link>
              </footer>
            </div>
          </div>
        )}

        {/* static-content */}

        <div id="supportWrap" className="support-wrap mb-0 pb-0 login-support">
          <div className="support-service">
            <Link
              id="support_whatsapp"
              to="javascript:void(0)"
              onClick={() => setOpen("tab1")}
              className={open == "tab1" ? "support-mail open" : "support-mail "}
              // className="support-whatsapp open"
            >
              <img
                src={
                  process.env.REACT_APP_IMAGE_URL +
                  "/assets/images/home/transparent.gif"
                }
                title="WhatsApp"
                alt=""
                className="support-mail"
              />
            </Link>
            <Link
              id="support_facebook"
              to="javascript:void(0)"
              onClick={() => setOpen("tab2")}
              className={
                open == "tab2" ? "support-whatsapp open" : "support-whatsapp "
              }
            >
              <img
                src={
                  process.env.REACT_APP_IMAGE_URL +
                  "/assets/images/home/transparent.gif"
                }
                alt=""
                title="WhatsApp"
                className="support-whatsapp"
              />
            </Link>

            <Link
              id="support_facebook"
              to="javascript:void(0)"
              onClick={() => setOpen("tab3")}
              className={
                open == "tab3" ? "support-telegram open" : "support-telegram "
              }
            >
              <img
                src={
                  process.env.REACT_APP_IMAGE_URL +
                  "/assets/images/home/transparent.gif"
                }
                alt=""
                title="WhatsApp"
                className="support-telegram"
              />
            </Link>

            <Link
              id="support_facebook"
              to="javascript:void(0)"
              onClick={() => setOpen("tab4")}
              className={
                open == "tab4"
                  ? "support-skype open"
                  : "support-skype text-white"
              }
            >
              <img
                src={
                  process.env.REACT_APP_IMAGE_URL +
                  "/assets/images/home/transparent.gif"
                }
                alt=""
                title="Skype"
                className="support-skype"
              />
            </Link>

            <Link
              id="support_facebook"
              to="javascript:void(0)"
              onClick={() => setOpen("tab5")}
              className={
                open == "tab5"
                  ? "support-skype open"
                  : "support-skype text-white"
              }
            >
              <img
                src={
                  process.env.REACT_APP_IMAGE_URL +
                  "/assets/images/home/transparent.gif"
                }
                alt=""
                title="Skype"
                className="support-ig"
              />
            </Link>
          </div>

          <div class="support-info">
            {open == "tab1" && (
              <div>
                <Link
                  id="emailContent"
                  className="ui-link text-white"
                  target="_blank"
                  to="mailto:info@maja365.live"
                >
                 info@maja365.live
                </Link>
              </div>
            )}
            {open == "tab2" && (
              <div>
                <div>
                  <Link
                    id="emailContent"
                    className="ui-link text-white"
                   
                    to="#"
                  >
                    
                  </Link>
                </div>
              </div>
            )}
            {open == "tab3" && (
              <div>
                <div>
                  <Link
                    id="emailContent"
                    className="ui-link text-white"
                  
                    to="https://t.me/+jrKjxT6hDFaawwbv"
                  >
                    https://t.me/+jrKjxT6hDFaawwbv
                  </Link>
                </div>
              </div>
            )}
            {open == "tab4" && (
              <div>
                <div>
                  <Link
                    id="emailContent"
                    className="ui-link text-white"
                   
                    to="#"
                  >
                   maza247betofficial
                  </Link>
                </div>
              </div>
            )}
            {open == "tab5" && (
              <div>
                <div>
                  <Link
                    id="emailContent"
                    className="ui-link text-white"
                   
                    to="#"
                  >officialmaza247bet
                   
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
