import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import AuthProvider from "../context/AuthContext";
import { apiGet } from "../Utils/apiFetch";
import apiPath from "../Utils/apiPath";
import obj from "../Utils/helpers";
import Slider from "react-slick";
import Aboutus from "../components/staticContent/Aboutus";
import GeneralTerms from "../components/staticContent/GeneralTerms";
import Kyc from "../components/staticContent/Kyc";
import PrivacyPolicy from "../components/staticContent/PrivacyPolicy";
import RulesRegulation from "../components/staticContent/RulesRegulation";
import ResponsibleGaming from "../components/staticContent/ResponsibleGaming";
function Home() {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
  };

  const [inplayCountData, setInplayCountData] = useState({});

  const navigate = useNavigate();
  let {
    isLoged,
    setIsLoged,
    setCasinoModel,
    setCasinoObj,
    launchCasino,
    user,
    open,
    setOpen,
    content,
    slug,
    setSlug,
    handleContentToggle,
    launchEGTCasino,
    dolaunchCasinoNew
  } = useContext(AuthProvider);
  const inPlayCount = async () => {
    const { status, data: response_users } = await apiGet(apiPath.inPlayCount);
    if (status === 200) {
      if (response_users.success) {
        if (response_users.results) {
          setInplayCountData(response_users.results);
        }
      }
    }
  };
  
  useEffect(() => {
    inPlayCount();
  }, []);
 
  return (
    <div>
      <div className="promo-viewport slider-banner">
        <Slider {...settings}>
          <div>
            <img
              src={
                process.env.REACT_APP_IMAGE_URL +
                "/assets/images/home/casino.png"
              }
              style={{ width: "100%",height: `18vw`}}
              alt=""
            />
          </div>
          <div>
            <img
              src={
                process.env.REACT_APP_IMAGE_URL +
                "/assets/images/home/live-casino-banner.png"
              }
              style={{ width: "100%" ,height: `18vw`}}
              alt=""
            />
          </div>
          <div>
            <img
              src={
                process.env.REACT_APP_IMAGE_URL +
                "/assets/images/home/banner-five.webp"
              }
              style={{ width: "100%",height: `18vw` }}
              alt=""
            />
          </div>
          {/* <div>
            <img
              src={
                process.env.REACT_APP_IMAGE_URL +
                "/assets/images/home/banner-six.webp"
              }
              style={{ width: "100%" }}
              alt=""
            />
          </div> */}
        </Slider>
      </div>
      <div id="page">
        <div className="mian-wrap">
          <div className="gamehall">
            <a href="#" neua="InPlay Banner">
              <dl className="entrance-title">
                <dt>Sports</dt>
                <dd>
                  <span>Play Now</span>
                </dd>
              </dl>
              <dl
                id="onLiveBoard"
                onClick={() => navigate("/Dinplay")}
                className="on_live"
              >
                <dt>
                  <p className="live_icon">
                    <span></span> LIVE
                  </p>
                </dt>

                <dd id="onLiveCount_CRICKET">
                  <p>Cricket</p>
                  <span id="count">
                    {inplayCountData?.cricketInplayCount || 0}
                  </span>
                </dd>

                <dd id="onLiveCount_SOCCER">
                  <p>Soccer</p>
                  <span id="count">
                    {inplayCountData?.soccerInplayCount || 0}
                  </span>
                </dd>

                {/* <dd id="onLiveCount_E_SOCCER">
                  <p>E-Soccer</p>
                  <span id="count">
                    {inplayCountData?.soccerInplayCount || 0}
                  </span>
                </dd> */}

                <dd id="onLiveCount_TENNIS">
                  <p>Tennis</p>
                  <span id="count">
                    {inplayCountData?.tennisInplayCount || 0}
                  </span>
                </dd>
              </dl>
              <Link to="/Dinplay">
                <img
                  src={
                    process.env.REACT_APP_IMAGE_URL +
                    "/assets/images/home/banner_sports.webp"
                  }
                  alt=""
                />
              </Link>
            </a>

            <a
              className="entrance"
              href="javascript:void(0)"
              onClick={() => {
                if (!isEmpty(user)) {
                  launchCasino({
                    platForm: "KINGMAKER",
                    gameType: "TABLE",
                    casinoType: "KM-TABLE-048",
                  });
                } else {
                  navigate("/Mlogin");
                }
              }}
            >
              <dl className="entrance-title">
                <dt>Horse Book</dt>
                <dd>
                  <span>Play Now</span>
                </dd>
              </dl>
              <img
                src={
                  process.env.REACT_APP_IMAGE_URL +
                  "/assets/images/home/horse.webp"
                }
                alt=""
              />
            </a>
            
            
            <a
              className="entrance-half"
              href="javascript:void(0)"
              onClick={() => {
                if (!isEmpty(user)) {
                  launchCasino({
                    platForm: "SEXYBCRT",
                    gameType: "LIVE",
                    casinoType: "MX-LIVE-002",
                  });
                } else {
                  navigate("/Mlogin");
                }
              }}
              neua="AEIndia Banner"
            >
              <dl className="entrance-title">
                <dt>Sexy</dt>
                <dd>
                  <span>Play Now</span>
                </dd>
              </dl>
              <img
                src={
                  process.env.REACT_APP_IMAGE_URL +
                  "/assets/images/home/aesexy.webp"
                }
                alt=""
              />
            </a>
            
            <a
              className="entrance-half"
              onClick={() => {
                if (!isEmpty(user)) {
                  launchCasino({
                    platForm: "KINGMAKER",
                    gameType: "TABLE",
                    casinoType: "KM-TABLE-036",
                  });
                } else {
                  navigate("/Mlogin");
                }
              }}
              href="javascript:void(0)"
              neua="Sicbo Banner"
            >
              <dl className="entrance-title">
                <dt>Cointoss</dt>
                <dd>
                  <span>Play Now</span>
                </dd>
              </dl>
              <img
                src={
                  process.env.REACT_APP_IMAGE_URL +
                  "/assets/images/home/cointoss.webp"
                }
                alt=""
              />
            </a>
            {/* <a
              className="entrance-half"
              href="javascript:void(0)"
              onClick={() => {
                if (!isEmpty(user)) {

                  launchCasino({
                    platForm: "1",
                    gameType: "",
                    casinoType: "",
                  });
                } else {
                  navigate("/login");
                }
              }}
              neua="Casino Banner"
            >
              <dl className="entrance-title">
                <dt>Live Casino</dt>
                <dd>Play Now</dd>
              </dl>
              <img
                src={
                  process.env.REACT_APP_IMAGE_URL +
                  "/assets/images/home/banner_casino-half.png"
                }
                alt=""
              />
            </a> */}
            <a
              className="entrance-half"
              href="javascript:void(0)"
              onClick={() => {
                if (!isEmpty(user)) {
                  launchCasino({
                    platForm: "EVOLUTION",
                    gameType: "LIVE",
                    casinoType: "",
                  });
                } else {
                  navigate("/login");
                }
              }}
              neua="Casino Banner"
            >
              <dl className="entrance-title">
                <dt>Evolution Casino</dt>
                <dd>
                  <span>Play Now</span>
                </dd>
              </dl>
              <img
                src={
                  process.env.REACT_APP_IMAGE_URL +
                  "/assets/images/home/evolutiongaming.webp"
                }
                alt=""
              />
            </a>

            <a
              className="entrance-half"
              id="pokerLoginBtn"
              neua="Bpoker Banner"
              onClick={() => {
                if (!isEmpty(user)) {
                  launchCasino({
                    platForm: "EVOLUTION",
                    gameType: "LIVE",
                    casinoType: "EVOLUTION-LIVE-006",
                    
                  });
                } else {
                  navigate("/login");
                }
              }}
              href="javascript:void(0)"
            >
              <dl className="entrance-title">
                <dt>Crazy Time</dt>
                <dd> <span>Play Now</span></dd>
              </dl>
              <img
                src={
                  process.env.REACT_APP_IMAGE_URL +
                  "/assets/images/home/pcrazytime.webp"
                }
                alt=""
              />
            </a>
            {/* <a
              className="entrance-half"
              href="javascript:void(0)"
              onClick={() => {
                if (!isEmpty(user)) {

                  launchCasino({
                    platForm: "VENUS",
                    gameType: "LIVE",
                    casinoType: "VN-LIVE-001",
                  });
                } else {
                  navigate("/login");
                }
              }}
              neua="Casino Banner"
            >
              <dl className="entrance-title">
                <dt>Venus Casino</dt>
                <dd>Play Now</dd>
              </dl>
              <img
                src={
                  process.env.REACT_APP_IMAGE_URL +
                  "/assets/images/home/venus-casino.jpeg"
                }
                alt=""
              />
            </a> */}

            <a
              className="entrance-half"
              href="javascript:void(0)"
              onClick={() => {
                if (!isEmpty(user)) {
                  launchCasino({
                    platForm: "KINGMAKER",
                    gameType: "TABLE",
                    casinoType: "KM-TABLE-021",
                  });
                } else {
                  navigate("/Mlogin");
                }
              }}
            >
              <dl className="entrance-title">
                <dt>Number Matka</dt>
                <dd>
                  <span>Play Now</span>
                </dd>
              </dl>
              <img
                src={
                  process.env.REACT_APP_IMAGE_URL +
                  "/assets/images/home/7d.webp"
                }
                alt=""
              />
            </a>

            <a
              className="entrance-half"
              href="javascript:void(0)"
              onClick={() => {
                if (!isEmpty(user)) {
                  launchCasino({
                    platForm: "KINGMAKER",
                    gameType: "TABLE",
                    casinoType: "KM-TABLE-022",
                  });
                } else {
                  navigate("/Mlogin");
                }
              }}
            >
              <dl className="entrance-title">
                <dt>Card Matka</dt>
                <dd>
                  <span>Play Now</span>
                </dd>
              </dl>
              <img
                src={
                  process.env.REACT_APP_IMAGE_URL +
                  "/assets/images/home/banner-sicbo-half.webp"
                }
                alt=""
              />
            </a>

            <a
              className=""
              href="javascript:void(0)"
              onClick={() => {
                if (!isEmpty(user)) {
                  launchCasino({
                    platForm: "KINGMAKER",
                    gameType: "TABLE",
                    casinoType: "KM-TABLE-060",
                  });
                } else {
                  navigate("/Mlogin");
                }
              }}
            >
              <dl className="entrance-title">
                <dt>Ludo Bet</dt>
                <dd>
                  <span>Play Now</span>
                </dd>
              </dl>
              <img
                src={
                  process.env.REACT_APP_IMAGE_URL +
                  "/assets/images/home/bb.webp"
                }
                alt=""
              />
            </a>

            <a
              className="entrance-half"
              href="javascript:void(0)"
              onClick={() => {
                if (!isEmpty(user)) {
                  launchCasino({
                    platForm: "KINGMAKER",
                    gameType: "TABLE",
                    casinoType: "KM-TABLE-028",
                  });
                } else {
                  navigate("/Mlogin");
                }
              }}
            >
              <dl className="entrance-title">
                <dt>7 UpDown</dt>
                <dd>
                  <span>Play Now</span>
                </dd>
              </dl>
              <img
                src={
                  process.env.REACT_APP_IMAGE_URL +
                  "/assets/images/home/banner_sevenUpDown-half.png"
                }
                alt=""
              />
            </a>

            <a
              className="entrance-half"
              href="javascript:void(0)"
              onClick={() => {
                if (!isEmpty(user)) {
                  launchCasino({
                    platForm: "KINGMAKER",
                    gameType: "TABLE",
                    casinoType: "KM-TABLE-015",
                  });
                } else {
                  navigate("/Mlogin");
                }
              }}
            >
              <dl className="entrance-title">
                <dt>Sicbo</dt>
                <dd>
                  <span>Play Now</span>
                </dd>
              </dl>
              <img
                src={
                  process.env.REACT_APP_IMAGE_URL +
                  "/assets/images/home/banner_sicbo-half.png"
                }
                alt=""
              />
            </a>
            <a
              className="entrance-half"
              href="javascript:void(0)"
              onClick={() => {
                if (!isEmpty(user)) {
                  launchCasino({
                    platForm: "KINGMAKER",
                    gameType: "TABLE",
                    casinoType: "KM-TABLE-032",
                  });
                } else {
                  navigate("/Mlogin");
                }
              }}
            >
              <dl className="entrance-title">
                <dt>Ander Bahar</dt>
                <dd>
                  <span>Play Now</span>
                </dd>
              </dl>
              <img
                src={
                  process.env.REACT_APP_IMAGE_URL +
                  "/assets/images/home/ander-bahar.png"
                }
                alt=""
              />
            </a> <a
              className="entrance-half"
              href="javascript:void(0)"
              onClick={() => {
                if (!isEmpty(user)) {
                  launchCasino({
                    platForm: "KINGMAKER",
                    gameType: "TABLE",
                    casinoType: "KM-TABLE-039",
                  });
                } else {
                  navigate("/Mlogin");
                }
              }}
            >
              <dl className="entrance-title">
                <dt>32 Cards</dt>
                <dd>
                  <span>Play Now</span>
                </dd>
              </dl>
              <img
                src={
                  process.env.REACT_APP_IMAGE_URL +
                  "/assets/images/home/banner-numbermatka-half.webp"
                }
                alt=""
              />
            </a>
            <a
              className="entrance-half"
              href="javascript:void(0)"
              onClick={() => {
                if (!isEmpty(user)) {
                  launchCasino({
                    platForm: "KINGMAKER",
                    gameType: "TABLE",
                    casinoType: "KM-TABLE-038",
                  });
                } else {
                  navigate("/Mlogin");
                }
              }}
            >
              <dl className="entrance-title">
                <dt>Blackjack</dt>
                <dd>
                  <span>Play Now</span>
                </dd>
              </dl>
              <img
                src={
                  process.env.REACT_APP_IMAGE_URL +
                  "/assets/images/home/banner-cardmatka-half.webp"
                }
                alt=""
              />
            </a>

            <a
              className="entrance-half"
              onClick={() => {
                if (!isEmpty(user)) {
                  launchCasino({
                    platForm: "KINGMAKER",
                    gameType: "TABLE",
                    casinoType: "KM-TABLE-042",
                  });
                } else {
                  navigate("/Mlogin");
                }
              }}
              href="javascript:void(0)"
              neua="TeenPatti Banner"
            >
              <dl className="entrance-title">
                <dt>Minesweeper</dt>
                <dd>
                  <span>Play Now</span>
                </dd>
              </dl>
              <img
                src={
                  process.env.REACT_APP_IMAGE_URL +
                  "/assets/images/home/mine.webp"
                }
                alt=""
              />
            </a>

            <a
              className=""
              href="javascript:void(0)"
              onClick={() => {
                if (!isEmpty(user)) {
                  launchCasino({
                    platForm: "SV388",
                    gameType: "LIVE",
                    casinoType: "SV-LIVE-001",
                  });
                } else {
                  navigate("/Mlogin");
                }
              }}
            >
              <dl className="entrance-title">
                <dt>Cock Fight</dt>
                <dd>
                  <span>Play Now</span>
                </dd>
              </dl>
              <img
                src={
                  process.env.REACT_APP_IMAGE_URL +
                  "/assets/images/home/cfight.webp"
                }
                alt=""
              />
            </a>
          </div>

          <div className="support-wrap extend-support bg-white">
            <div className="support-social">
              <div className="social-btn">
                <img
                  src={
                    process.env.REACT_APP_IMAGE_URL +
                    "/assets/images/home/transparent.gif"
                  }
                  title="WhatsApp"
                  className="support-mail"
                />
                {/* <a href="https://chat.whatsapp.com/J1ylODOZNWBKfrLdIYm1uE">
                  Group
                </a> */}
              </div>

              <div className="social-btn">
                <img
                  src={
                    process.env.REACT_APP_IMAGE_URL +
                    "/assets/images/home/transparent.gif"
                  }
                  title="WhatsApp"
                  className="support-whatsapp"
                />
                {/* <a href="https://chat.whatsapp.com/J1ylODOZNWBKfrLdIYm1uE">
                  Group
                </a> */}
              </div>

              <div className="social-btn">
                <img
                  src={
                    process.env.REACT_APP_IMAGE_URL +
                    "/assets/images/home/transparent.gif"
                  }
                  title="WhatsApp"
                  className="support-telegram"
                />
                {/* <a href="https://chat.whatsapp.com/J1ylODOZNWBKfrLdIYm1uE">
                  Group
                </a> */}
              </div>

              <div className="social-btn">
                <img
                  src={
                    process.env.REACT_APP_IMAGE_URL +
                    "/assets/images/home/transparent.gif"
                  }
                  title="Skype"
                  className="support-skype"
                />
              </div>
              <div className="social-btn">
                <img
                  src={
                    process.env.REACT_APP_IMAGE_URL +
                    "/assets/images/home/transparent.gif"
                  }
                  title="Skype"
                  className="support-ig"
                />
              </div>
            </div>
          </div>

          <ul className="policy-link home-policy-link">
            <li>
              <Link
                to="javascript:void(0)"
                onClick={() => {
                  handleContentToggle();
                  setSlug("about");
                }}
              >
                About us
              </Link>
            </li>

            <li>
              <Link
                to="javascript:void(0)"
                onClick={() => {
                  handleContentToggle();
                  setSlug("term");
                }}
              >
                Terms and Conditions
              </Link>
            </li>

            <li>
              <Link
                to="javascript:void(0)"
                onClick={() => {
                  handleContentToggle();
                  setSlug("kyc");
                }}
              >
                KYC
              </Link>
            </li>

            <li>
              <Link
                to="javascript:void(0)"
                onClick={() => {
                  handleContentToggle();
                  setSlug("privacy");
                }}
              >
                Privacy Policy
              </Link>
            </li>

            <li>
              <Link
                to="javascript:void(0)"
                onClick={() => {
                  handleContentToggle();
                  setSlug("rules");
                }}
              >
                Rules and Regulations
              </Link>
            </li>

            <li>
              <Link
                to="javascript:void(0)"
                onClick={() => {
                  handleContentToggle();
                  setSlug("responsible");
                }}
              >
                Responsible Gaming
              </Link>
            </li>
            {/* <li>
              <a href="#">About Us</a>
            </li>
            <li>
              <a href="#">Self-Exclusion Policy</a>
            </li>
            <li>
              <a href="#">Underage Policy</a>
            </li> */}
          </ul>

          {/* static-content */}

          {content && (
            <div className="frame-wrap">
              <div className="news-wrap news-wrap-frame">
                {slug === "about" && <Aboutus />}
                {slug === "term" && <GeneralTerms />}
                {slug === "kyc" && <Kyc />}
                {slug === "privacy" && <PrivacyPolicy />}
                {slug === "rules" && <RulesRegulation />}
                {slug === "responsible" && <ResponsibleGaming />}

                <footer>
                  <Link
                    to="#"
                    className="btn-send"
                    onClick={() => {
                      handleContentToggle();
                      setSlug("");
                    }}
                  >
                    CLOSE
                  </Link>
                </footer>
              </div>
            </div>
          )}

          {/* static-content */}

          <div className="extra-wrap">
            <div
              id="powerWrap"
              className="power-wrap-b"
              style={{ display: "block" }}
            >
              <span>Powered by</span>
              <img
                src={
                  process.env.REACT_APP_IMAGE_URL +
                  "/assets/images/home/transparent.gif"
                }
              />
            </div>

            <div className="appdl-link-android" style={{ display: "block" }}>
              <a href="#">
                <img
                  src={
                    process.env.REACT_APP_IMAGE_URL +
                    "/assets/images/home/btn-appdl-android.png"
                  }
                  alt=""
                />
              </a>
              <p>v1.07 - 2020-11-11 - 8.2MB</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
