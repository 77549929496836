import { isEmpty } from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { Link ,useNavigate} from "react-router-dom";
import AuthProvider from "../../context/AuthContext";
import BetSlipContext from "../../context/BetSlipContext";
import { apiGet } from "../../Utils/apiFetch";
import apiPath from "../../Utils/apiPath";
import InplayTabs from "./InplayTabs";
import io from "socket.io-client";

function Inplay() {
  const navigate = useNavigate();
  const [type, setType] = useState("inplay");
  let { user, setLoader, launchCasino, parly, setInplaySocket, inplaySocket } =
    useContext(AuthProvider);
  let { handelAddMultiMarket } = useContext(BetSlipContext);
  const [loading, setLoading] = useState(false);
  const [sportsData, setSportsData] = useState([]);
  const [inPlayScore, setInPlayScore] = useState([]);
  const [socket, setSocket] = useState({});
  const [checkRefresh, setCheckRefresh] = useState(true);
  const inplayScoreSocket = async () => {
    setCheckRefresh(false);
    if (!isEmpty(socket)) {
      socket.disconnect();
    }
    if (!isEmpty(inplaySocket)) {
      inplaySocket.disconnect();
    }
    let randomId = user?.user?._id ? user?.user?._id : 112233;
    const newSocket = io(
      `${process.env.REACT_APP_API_BASE_URL2}?token=${randomId}&userType=front`,
      {
        transports: ["websocket"],
      }
    );
    setLoading(false);
    setInplaySocket(newSocket);
    setSocket(newSocket);
    const InPlayScore = (message) => {
      setInPlayScore(message);
    };
    newSocket.on("scoreAll", InPlayScore);
    newSocket.on("disconnect", function () {
      setCheckRefresh(true);
    });
    return () => newSocket.close();
  };
  const fetchAllSport = async (obj) => {
    if (isEmpty(obj)) {
      setLoader(true);
    }
    let temp = user ? `&userId=${user.user._id}` : "";
    let parlyType = parly ? `&pagetype=multi` : "";
    const { status, data: response_users } = await apiGet(
      apiPath.userInPlaySport +
        `?type=${!isEmpty(obj) ? obj : type}` +
        temp +
        parlyType
    );

    if (status === 200) {
      setLoader(false);
      setLoading(false);
      if (response_users.success) {
        setLoader(false);
        setSportsData(response_users.results[type]);
        if (inPlayScore?.length > 0) {
          setSportsData(
            response_users.results[type].map((res) => {
              let temp = inPlayScore.find(
                (item) => item?.eventId == res?.eventId
              );
              return {
                ...res,
                score:
                  temp?.eventId == res?.eventId
                    ? temp?.score == 0
                      ? ""
                      : temp?.score
                    : "",
                timeElapsed:
                  temp?.eventId == res?.eventId ? temp?.timeElapsed : "",
              };
            })
          );
        }
        setLoader(false);
      }
    }
    setLoading(false);
    setLoader(false);
  };
  useEffect(() => {
    if (loading) {
      fetchAllSport();
      inplayScoreSocket();
    }
  }, [loading]);

  useEffect(() => {
    fetchAllSport();
  }, [type,parly]);

  useEffect(() => {
    if (checkRefresh) {
      inplayScoreSocket();
    }
  }, [checkRefresh]);

  useEffect(() => {
    let interval = setInterval(() => {
      inplayScoreSocket();
      fetchAllSport(type);
    }, 60000);
    return () => {
      clearInterval(interval);
    };
  }, [type]);

  document.addEventListener("visibilitychange", function () {
    if (!document.hidden && !checkRefresh) {
      if (!isEmpty(socket)) {
        socket.disconnect();
      }
      setCheckRefresh(true);
    } else {
      setCheckRefresh(false);
    }
  });
  useEffect(() => {
    if (inPlayScore?.length > 0) {
      setSportsData((current) => {
        return current.map((res) => {
          let temp = inPlayScore.find((item) => item?.eventId == res?.eventId);
          return {
            ...res,
            score:
              temp?.eventId == res?.eventId
                ? temp?.score == 0
                  ? ""
                  : temp?.score
                : "",
            timeElapsed: temp?.eventId == res?.eventId ? temp?.timeElapsed : "",
          };
        });
      });
    }
  }, [inPlayScore]);
  return (
    <>
      <div className="mian-wrap">
        <div className="tab-wrap">
          <ul>
            <li
              id="inplay"
              className={type == "inplay" ? "select" : ""}
              onClick={() => {
                setType("inplay");

              }}
            >
              <a href="javascript:void(0)" neua="In-Play">
                In-Play
              </a>
            </li>
            <li
              id="today"
              className={type == "today" ? "select" : ""}
              onClick={() => {
                setType("today");

              }}
            >
              <a href="javascript:void(0)">Today</a>
            </li>
            <li
              id="tomorrow"
              className={type == "tomorrow" ? "select" : ""}
              onClick={() => {
                setType("tomorrow");

              }}
            >
              <a href="javascript:void(0)">Tomorrow</a>
            </li>
            {/* <li id="result">
              <Link to={isEmpty(user) ? "/login" : "/result"}>Result</Link>
            </li> */}
          </ul>
          {/* <a
            className="a-search"
            onClick={() => setSearchToogle(true)}
            href="javascript:void(0)"
          >
            Search
          </a> */}
        </div>
        {
          sportsData && sportsData.length > 0 && (
            <>
            {sportsData?.filter((res) => res.gameType=="cricket")?.length>0 &&
              <InplayTabs
                type={type}
                data={sportsData || []}
                handelAddMultiMarket={handelAddMultiMarket}
                setLoading={setLoading}
                sportsType={"Cricket"}
                sportsId={4}
              />}
              <div id="inplayVirtualCricketImage" className="footer-kv">
            <a id="inplayVirtualCricketEntrance" className="entrance" 
                onClick={() => {
                  if (!isEmpty(user)) {
                    //
                    launchCasino({
                      platForm: "SABA",
                      gameType: "VIRTUAL",
                      casinoType: "SABA-VIRTUAL-001",
                    });
                  } else {
                    navigate("/login");
                  }
                }}
                >
              <img
                src="assets/images/kv-virtual-cricket-m.jpg"
                style={{ width: "100%" }}
              />
            </a>
          </div>
              {sportsData?.filter((res) => res.gameType=="soccer")?.length>0 &&
              <InplayTabs
                type={type}
                data={sportsData || []}
                handelAddMultiMarket={handelAddMultiMarket}
                setLoading={setLoading}
                sportsType={"Soccer"}
                sportsId={1}
              />
          }
              {sportsData?.filter((res) => res.gameType=="tennis")?.length>0 &&
              <InplayTabs
                type={type}
                data={sportsData || []}
                handelAddMultiMarket={handelAddMultiMarket}
                setLoading={setLoading}
                sportsType={"Tennis"}
                sportsId={2}
              />}
              </>
          )
        }

      </div>
    </>
  );
}

export default Inplay;
