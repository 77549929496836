import React from "react";

function Backhome() {
  return (
    <>
      <div className="back_home">
        <a
          id="apiWalletReturnUrl"
          className="btn-back_home ui-link"
          href="/home"
        ></a>
        <h1></h1>
      </div>
    </>
  );
}

export default Backhome;
