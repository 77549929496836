import React, { createContext, useState, useEffect } from "react";
import jwt_decode from "jwt-decode";
import { pick, isEmpty } from "lodash";
import { useLocation, useNavigate } from "react-router-dom";
import { apiPost, apiGet } from "../Utils/apiFetch";
import apiPath from "../Utils/apiPath";
import io from "socket.io-client";
import { toast } from "wc-toast";
import secureLocalStorage from "react-secure-storage";
const AuthContext = createContext();
export default AuthContext;
export const AuthProvider = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  let [user, setUser] = useState(
    localStorage.getItem("token")
      ? jwt_decode(localStorage.getItem("token"))
      : ""
  );
  const [inplaySocket, setInplaySocket] = useState({});
  const [casinoComingSoonModel, setCasinoComingSoonModel] = useState(false);
  const [gameFooter, setGameFooter] = useState(false);
  const [casinoRangeAmount, setCasinoRangeAmount] = useState(0);
  const [marketDepthData, setMarketDepthData] = useState({});
  const [marketDepth, setMarketDepth] = useState(false);
  const [casinoModel, setCasinoModel] = useState(false);
  const [casinoObj, setCasinoObj] = useState({});
  const [egtCasinoModel, setEgtCasinoModel] = useState(false);
  const [egtCasinoObj, setEgtCasinoObj] = useState({});
  const [sokcetId, setSocketId] = useState({});
  const [fancyrules, setFancyRules] = useState(false);
  const [premiumFancyRules, setPremiumFancyRules] = useState(false);
  const [profileData, setProfileData] = useState({});
  const [loader, setLoader] = useState(false);
  const [openBetsToogle, setOpenBetsToogle] = useState(false);
  const [searchToogle, setSearchToogle] = useState(false);
  const [settingToogle, setSettingToogle] = useState(false);
  const [announcmentToogle, setAnnouncmentToogle] = useState(false);
  const [walletOpen, setWalletOpen] = useState(false);
  const [openBetSelection, setOpenBetSelection] = useState("open");
  const [isLoged, setIsLoged] = useState("");
  let [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(true);
  const [content, setContent] = useState(false);
  const [slug, setSlug] = useState("");
  const handleContentToggle = () => {
    setContent(!content);
  };
  const [isTv, setIsTv] = useState({
    status: true,
    id: "",
  });
  const [checkRefresh, setCheckRefresh] = useState(true);
  let [user_coins, setUserCoins] = useState({
    exposure: 0.0,
    totalCoins: 0.0,
  });

  useEffect(() => {
    if (location?.pathname?.split("/")[1] !== "match-details") {
      setIsTv({ id: "", status: false });
    }
  }, [location?.pathname]);

  const [parly, setParly] = useState(false);
  const [parlyBet, setParlyBet] = useState([]);
  const [showParlyBet, setShowParlyBet] = useState(false);
  useEffect(() => {
    if (localStorage.getItem("parly") == "true") {
      setParly(true);
    } else {
      setParly(false);
    }
  }, []);
  const updateParly = (value) => {
    localStorage.setItem("parly", value);
    setParly(value);
  };
  useEffect(() => {
    if (secureLocalStorage.getItem("parlyBet")?.length > 0) {
      setParlyBet(secureLocalStorage.getItem("parlyBet"));
    }
  }, []);

  useEffect(() => {
    if (
      location?.pathname?.split("/")[1] !== "match-details" &&
      !isEmpty(sokcetId)
    ) {
      sokcetId.disconnect();
      setSocketId({});
    }
    if (
      location?.pathname?.split("/")[1] !== "inplay" &&
      !isEmpty(inplaySocket)
    ) {
      inplaySocket.disconnect();
      setInplaySocket({});
    }
  }, [location]);

  let logoutUser = () => {
    setUser(null);
    localStorage.removeItem("token");
    localStorage.removeItem("refresh_token");
    navigate("/");
  };

  const [authSocket, setAuthSocket] = useState({});

  const amounutRefresh = async () => {
    setCheckRefresh(false);
    if (!isEmpty(authSocket)) {
      authSocket.disconnect();
    }
    const newSocket = io(
      `${process.env.REACT_APP_API_BASE_URL_OLD}?token=${localStorage.getItem(
        "token"
      )}&userType=front`,
      {
        transports: ["websocket"],
      }
    );
    const coinListener = (message) => {
      setUserCoins(message.results);
    };

    const forceLogout = (message) => {
      const uniqueId = localStorage.getItem("uniqueId");
      if (uniqueId !== message.results.uniqueId) {
        logoutUser();
      }
    };
    setAuthSocket(newSocket);
    newSocket.emit("getCoins", { user_id: user ? user.user._id : "" });
    newSocket.on("listenGetCoin", coinListener);
    newSocket.on("listenForceLogout", forceLogout);
    newSocket.on("getCoinEvent", getCoinEvent);
    newSocket.on("disconnect", function () {
      setCheckRefresh(true);
    });
    return () => newSocket.close();
  };

  const getCoinEvent = async () => {
    if (!isEmpty(user)) {
      const { status, data } = await apiGet(apiPath.refreshAmount);
      if (status === 200) {
        if (data.success) {
          setUserCoins({
            exposure: data?.results?.exposure,
            totalCoins: data?.results?.totalCoins,
          });
        }
      }
    }
  };
  useEffect(() => {
    if (!isEmpty(user)) {
      amounutRefresh();
    }
  }, [user, checkRefresh]);

  document.addEventListener("visibilitychange", function () {
    if (!document.hidden && !isEmpty(user) && !checkRefresh) {
      if (!isEmpty(authSocket)) {
        authSocket.disconnect();
      }
      setCheckRefresh(true);
    } else {
      setCheckRefresh(false);
    }
  });

  let loginUser = async (body) => {
    let hostname = window.location.hostname;
    hostname = hostname.replace(/^www\./, "");
    hostname = hostname.replace(/^ag\./, "");
    body.website = hostname || "SABAEXCH";
    const { status, data } = await apiPost(
      apiPath.loginUser,
      pick(body, ["username", "password", "uniqueId", "website"])
    );
    if (status === 200) {
      if (data.success) {
        localStorage.setItem("token", data.results.token);
        localStorage.setItem("refresh_token", data.results.refresh_token);
        setUserCoins({
          exposure: data.results.exposure,
          totalCoins: data.results.totalCoins,
        });
        setUser(jwt_decode(data.results.token));
        setTimeout(() => {
          navigate("/");
          window.location.reload();
        }, 500);
        //setLoader(false);
        setIsLoged("loged");
      } else {
        //setLoader(false);
        setIsLoged("failed");
      }
    } else {
      //setLoader(false);
      setIsLoged("failed");
    }
  };

  const launchCasino = async (request) => {
    if (request?.platForm || request?.gameType || request?.casinoType) {
      setLoader(true);
      const { status, data: response_users1 } = await apiPost(
        apiPath.casinoAmountAdd,
        { amount: 0, platForm: request?.platForm }
      );
      if (status === 200) {
        if (response_users1.success) {
          const { status, data: response_users } = await apiPost(
            request?.platForm && request?.gameType && request?.casinoType
              ? apiPath.doLoginAndLaunchGame
              : apiPath.casinoWalletLogin,
            request?.platForm && request?.gameType && request?.casinoType
              ? {
                  userId: response_users1?.results?.aeCasinoUserId,
                  platForm: request?.platForm == "1" ? "" : request?.platForm,
                  gameType: request?.gameType,
                  gameCode: request?.casinoType,
                }
              : {
                  userId: response_users1?.results?.aeCasinoUserId,
                  gameType: request?.gameType,
                  platForm: request?.platForm == "1" ? "" : request?.platForm,
                }
          );
          if (status === 200) {
            if (response_users.status) {
              setLoader(false);
              if (response_users.data.status === "0000") {
                window.location.href = response_users.data.url;
                // javascript: window.open(
                //   response_users.data.url,
                //   "_blank",
                //   "height=900,width=1200"
                // );
              } else {
                setLoader(false);
                toast.error(response_users?.data?.desc);
              }
            } else {
              setLoader(false);
              toast.error(response_users?.message);
            }
          } else {
            setLoader(false);
            toast.error(response_users?.message);
          }
        } else {
          setLoader(false);
          toast.error(response_users1?.message);
        }
      } else {
        setLoader(false);
        toast.error(response_users1?.message);
      }
    } else {
      setCasinoComingSoonModel(true);
      setLoader(false);
    }
  };

  const [eGTGoConnection, setEGTGoConnection] = useState(false);

  const launchEGTCasino = async (request) => {
    //console.log('request-------------------', request)
    if (request?.platForm || request?.gameType || request?.casinoType) {
      setLoader(true);
      const { status, data: response_users1 } = await apiPost(
        apiPath.easytogoCasinoAmountAdd,
        {
          prod: request?.platForm,
          type: request?.gameType,
        }
      );
      if (status === 200) {
        if (response_users1.success) {
          const { status, data: response_users } = await apiPost(
            apiPath.doLoginAndLaunchEasyToCasino,
            {
              prod: request?.platForm,
              type: request?.gameType,
              gameCode: request?.casinoType,
              gameid: request?.gameid || false,
            }
          );
          if (status === 200 && response_users.status) {
            // console.log('response_users0', response_users)
            setLoader(false);
            if (response_users.data.err === 1) {
              localStorage.setItem("launchEGTCasino", request?.platForm);
              window.location.href = response_users.data.url;
            } else {
              toast.error(response_users?.message);
              setLoader(false);
            }
          } else {
            toast.error(response_users?.message);
            setLoader(false);
          }
        } else {
          toast.error(response_users1?.message);
          setLoader(false);
        }
      } else {
        toast.error(response_users1?.message);
        setLoader(false);
      }
    } else {
      setCasinoComingSoonModel(true);
      setLoader(false);
    }
  };
  const launchGis = async (request) => {
    //console.log('request-------------------', request)
    if (request?.gameid) {
      setLoader(true);

      const { status, data: response_users } = await apiPost(
        apiPath.doLoginAndLaunchGisCasino,
        {
          gameid: request?.gameid || false,
        }
      );
      if (status === 200 && response_users.status) {
        // console.log('response_users0', response_users)
        setLoader(false);
        if (response_users.data.err === 1) {
          window.location.href = response_users.data.url;
        } else {
          toast.error(response_users?.message);
          setLoader(false);
        }
      } else {
        toast.error(response_users?.message);
        setLoader(false);
      }
    } else {
      toast.error("Game code missing");
      setLoader(false);
    }
  };

  const dolaunchCasinoNew = async (request) => {
    if (request?.gameCode) {
      const { status, data: response_users1 } = await apiPost(
        apiPath.doLoginAndLaunchIQCasino,
        {
          gameid: request?.gameCode,
          legacy: request?.legacy || "",
        }
      );
      if (status === 200) {
        if (response_users1.status) {
          window.location.href = response_users1.data;
        } else {
          toast.error(
            response_users1?.message == "success"
              ? "Casino is in maintenance mode if you have any query please contact upline"
              : response_users1?.message ||
                  "Casino is in maintenance mode if you have any query please contact upline"
          );
        }
      }
    }
  };
  const getEGTCasinoBalance = async (prod) => {
    // localStorage.removeItem("launchEGTCasino");
    const { status, data: response_users } = await apiGet(
      apiPath.getEGTCasinoAmount + "?prod=" + prod
    );
    if (status === 200) {
      if (response_users.success) {
        // console.log("response_users", response_users);
      }
    }
  };

  useEffect(() => {
    const launchEGTCasinoStatus = localStorage.getItem("launchEGTCasino");
    if (!isEmpty(user) && launchEGTCasinoStatus) {
      getEGTCasinoBalance(launchEGTCasinoStatus);
    }
  }, [eGTGoConnection]);

  document.addEventListener("visibilitychange", function () {
    const launchEGTCasinoStatus = localStorage.getItem("launchEGTCasino");
    // console.log('launchEGTCasinoStatus', launchEGTCasinoStatus)
    if (!document.hidden && launchEGTCasinoStatus) {
      setEGTGoConnection(true);
    }
  });
  let contextData = {
    user: user,
    loginUser: loginUser,
    logoutUser: logoutUser,
    user_coins,
    setUserCoins: setUserCoins,
    setOpenBetsToogle,
    openBetsToogle,
    setSettingToogle,
    settingToogle,
    setAnnouncmentToogle,
    announcmentToogle,
    setSearchToogle,
    searchToogle,
    setLoader,
    loader,
    isLoged,
    setIsLoged,
    setIsTv,
    isTv,
    setWalletOpen,
    walletOpen,
    setProfileData,
    profileData,
    setFancyRules,
    fancyrules,
    setPremiumFancyRules,
    premiumFancyRules,
    setCasinoModel,
    casinoModel,
    setCasinoRangeAmount,
    casinoRangeAmount,
    setCasinoObj,
    casinoObj,
    setOpenBetSelection,
    openBetSelection,
    setMarketDepth,
    marketDepth,
    setMarketDepthData,
    marketDepthData,
    amounutRefresh,
    setGameFooter,
    gameFooter,
    open,
    setOpen,
    content,
    setContent,
    slug,
    setSlug,
    handleContentToggle,
    launchCasino,
    setCasinoComingSoonModel,
    casinoComingSoonModel,
    launchEGTCasino,
    egtCasinoObj,
    setEgtCasinoObj,
    egtCasinoModel,
    setEgtCasinoModel,
    getCoinEvent,
    launchGis,
    setParly: updateParly,
    parly,
    parlyBet,
    setParlyBet,
    showParlyBet,
    setShowParlyBet,
    dolaunchCasinoNew,
    sokcetId,
    setSocketId,
    inplaySocket,
    setInplaySocket,
  };
  return (
    <AuthContext.Provider value={contextData}>
      {loading ? null : children}
    </AuthContext.Provider>
  );
};
