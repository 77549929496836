import React, { useContext } from "react";
// import "bootstrap/dist/css/bootstrap.min.css";
// import "@fortawesome/fontawesome-free/css/all.css";
import "../src/assets/css/sky.css";
// import "../src/assets/css/style.css";
// import "../src/assets/css/responsive.css";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Inplay from "./pages/Inplay/Inplay";
import Today from "./pages/Today";
import Tomorrow from "./pages/Tomorrow";
import Result from "./pages/Result";
import Profile from "./pages/Profile";
import MultiMarket from "./pages/MultiMarket/Multimarket";
import Sports from "./pages/Sports";
import SportsDetail from "./pages/SportsDetail";
import BidDetail from "./pages/MatchDetails/BidDetail";

import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Footer from "./components/Footer";
import Header from "./components/Header";
import AuthProvider from "./context/AuthContext";
import AllOverlay from "./components/All_overlay";
import { useEffect } from "react";
import { isEmpty } from "lodash";
import LiveCasino from "./pages/LiveCasino";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
import Blog from "./pages/Blog";
function App() {
  const location = useLocation();
  const navigate = useNavigate();
  let { user } = useContext(AuthProvider);
  useEffect(() => {
    if (isEmpty(user)) {
      navigate("/");
    }
  }, [user]);
  
  useEffect(() => {
    if (isBrowser) {
      window.location.replace(process.env.REACT_APP_WEB_URL);
    }
  }, [isMobile, isBrowser]);
  return (
    <div className="App">
      <AllOverlay />
      <wc-toast position="top-right"></wc-toast>
      {location.pathname !== "/Mlogin" && location.pathname !== "/blog" && (
        <Header />
      )}
      <Routes>
        <Route exact path="/" element={<Home />}></Route>
        <Route exact path="/Duserhome" element={<Home />}></Route>
        <Route exact path="/login" element={<Login />}></Route>
        <Route exact path="/Mlogin" element={<Login />}></Route>
        <Route exact path="/Dinplay" element={<Inplay />}></Route>
        <Route exact path="/today" element={<Today />}></Route>
        <Route exact path="/tomorrow" element={<Tomorrow />}></Route>
        <Route exact path="/result" element={<Result />}></Route>
        <Route exact path="/Maccount" element={<Profile />}></Route>
        <Route exact path="/DmultiMarket" element={<MultiMarket />}></Route>
        <Route exact path="/Dsports" element={<Sports />}></Route>
        <Route exact path="/sportsdetail" element={<SportsDetail />}></Route>
        <Route exact path="/live-casino" element={<LiveCasino />}></Route>
        <Route exact path="/blog" element={<Blog />}></Route>
        <Route
          exact
          path="/match-details/:eventId/:marketId"
          element={<BidDetail />}
        ></Route>
      </Routes>
      {location.pathname !== "/Mlogin" && location.pathname !== "/blog" && (
        <Footer />
      )}
    </div>
  );
}
export default App;
