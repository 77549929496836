import React from "react";
import helper from "../Utils/helpers";
import { HeaderListing, TableBody } from "./BetsListing";
import { startCase } from "lodash";
const ParlyFIltered = ({
  filtered,
  type,
  sortbyTime,
  betinfo,
  betType,
  clearBets,
}) => {
  return (
    <div id="txnList" className="slip-list" style={{ display: "block" }}>
      <dl className="bets-head" style={{ display: "flex" }}>
        <dt>
          <h4>Fancy Name</h4>
        </dt>
        <dd className="col-odds">
          <span>{"Odds"}</span>
        </dd>
        <dd className="col-stake">
          <span>Win/Loss</span>
        </dd>
      </dl>
      {filtered?.map((res) => {
        return (
          <>
            <dl class={"bets-back"} style={{ display: "flex" }}>
              <dt>
                <span
                  style={{ fontSize: "10px", fontWeight: "700" }}
                  class={"back"}
                  id="sideType"
                >
                  {helper?.betCheckObj[res?.eventType]}
                </span>
                <h4 style={{ flex: "0 0 40.8vw" }} id="selectionName">
                  {res?.fancyName}
                </h4>
              </dt>
              <dd id="odds" class="col-odds">
                <span>{Number(res.bhav)?.toFixed(2)}</span>
              </dd>
              <dd id="stake" class="col-stake" title=" 100">
                <span>
                  {res?.isDeclared ? (
                    <>
                      {res?.result == "loss" ? (
                        <strong className="d-block" style={{ color: "red" }}>
                          {startCase(res?.result)}
                        </strong>
                      ) : (
                        <strong className="d-block" style={{ color: "green" }}>
                          {startCase(res?.result)}
                        </strong>
                      )}
                    </>
                  ) : (
                    "-"
                  )}
                </span>
              </dd>
              {/* <dd id="betInfo" class="col-ref" style={{ order: "revert" }}>
                <ul>
                  <li id="betId">betId:{res.matchBetId}</li>
                  <li id="placeDate">{helper.dateFormat(res.timeInserted)}</li>
                </ul>
              </dd> */}
            </dl>
          </>
        );
      })}
    </div>
  );
};

export default ParlyFIltered;
