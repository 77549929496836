import React, { useContext, useEffect, useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import AuthContext from "../../context/AuthContext";
import { RxCross2 } from "react-icons/rx";
import { Button } from "react-bootstrap";
import { BiSolidRightArrow } from "react-icons/bi";
import { MdDeleteForever } from "react-icons/md";
import { IoIosArrowForward } from "react-icons/io";
import obj from "../../Utils/constants";
import secureLocalStorage from "react-secure-storage";
import StackSetting from "./StackSetting";
import { AiFillSetting } from "react-icons/ai";
import moment from "moment";
import { apiGet, apiPost } from "../../Utils/apiFetch";
import apiPath from "../../Utils/apiPath";
import BetSlipContext from "../../context/BetSlipContext";
import loading from "../../assets/images/loading_bet.webp";
const BetSlipParly = () => {
  const numberArrayCount = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0, "00", "img"];
  const numberArray = [10, 100, 200, 500];
  const {
    setShowParlyBet,
    setParlyBet,
    parlyBet,
    profileData,
    amounutRefresh,
    setMessage,
    message,
    setLoader,
  } = useContext(AuthContext);
  const {
    setIsBetPlaced,
    setMessageBox,
    setBetPlacedCheck,
    betSelectionObj,
    setBetSelectionObj,
  } = useContext(BetSlipContext);
  const [stake, setStake] = useState("");
  const [showStackSetting, setShowStackSetting] = useState(false);
  const [showDial, setShowDial] = useState(false);
  const [totalOdds, setTotalOdds] = useState("");
  const handleStackSetting = () => {
    setShowStackSetting(!showStackSetting);
  };
  const [loaderNew, setLoaderNew] = useState(false);
  const removeSlip = (id) => {
    setParlyBet((current) => {
      if (current?.length == 1) {
        secureLocalStorage.setItem("parlyBet", []);
      } else {
        secureLocalStorage.setItem(
          "parlyBet",
          current?.filter((res) => {
            return res?.newEventId !== id;
          })
        );
      }
      return current?.filter((res) => {
        return res?.newEventId !== id;
      });
    });
    if (parlyBet?.length == 1) {
      setShowParlyBet(false);
    }
  };

  const handelNumberChange = (item) => {
    setStake(item);
  };

  const handelDialPad = (item) => {
    let str = stake?.toString() + item?.toString();
    if (str?.length > 6) {
    } else {
      setStake(Number(str));
    }
  };

  useEffect(() => {
    if (parlyBet?.length > 0) {
      setTotalOdds(
        parlyBet
          ?.filter((res) => !res?.closed)
          ?.reduce(
            (accumulator, currentValue) =>
              accumulator * currentValue?.sportsBookSelectionObject?.odds,
            1
          )
          ?.toFixed(2)
      );
    }
  }, [parlyBet]);


  useEffect(() => {
    let interval = setInterval(() => {
      getOdds(parlyBet);
    }, 3000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const getOdds = async (parlyBet) => {
    const { status, data: response_users } = await apiGet(
      apiPath.getPremiumFancy +
        `?events=${parlyBet
          ?.map((res) => {
            return res?.newEventId;
          })
          .toString()
          .trim()}&multi=true`
    );
    if (status === 200) {
      if (response_users.success) {
        setParlyBet((current) => {
          let result = current?.map((item) => {
            let obj = response_users?.results.find(
              (res) => res?.eventId == item?.eventId && res?.id == item?.id
            );
            if (obj?.eventId == item?.eventId && obj?.id == item?.id) {
              return {
                id: obj?.id,
                eventId: obj?.eventId,
                apisite: obj?.apisite,
                apiSiteMarketId: obj?.apiSiteMarketId,
                eventType: obj?.eventType,
                betfairEventId: obj?.betfairEventId,
                marketName: obj?.marketName,
                numberOfWinner: obj?.numberOfWinner,
                numberOfActiveRunners: obj?.numberOfActiveRunners,
                marketStatus: obj?.marketStatus,
                isExpand: obj?.isExpand,
                closeSite: obj?.closeSite,
                bookMode: obj?.bookMode,
                apiSiteStatus: obj?.apiSiteStatus,
                apiSiteSpecifier: obj?.apiSiteSpecifier,
                updateDate: obj?.updateDate,
                min: obj?.min,
                max: obj?.max,
                sportsBookSelection: obj?.sportsBookSelection,
                selectionTs: obj?.selectionTs,
                odd_ts: obj?.odd_ts,
                ts: obj?.ts,
                check: obj?.check,
                sportsBookSelectionObject: obj?.sportsBookSelection?.find(
                  (pop) => {
                    return item?.sportsBookSelectionObject?.id == pop?.id;
                  }
                ),
                selectObj: obj?.selectObj,
                newEventId: item?.newEventId,
                eventName: item?.eventName,
                marketId: item?.marketId,
                closed: false,
              };
            } else {
              return { ...item, closed: true };
            }
          });
          secureLocalStorage.setItem("parlyBet", result);
          return result;
        });
      }
    }
  };

  const clear = () => {
    let str = stake?.toString();
    if (str?.length == 1) {
      setStake("");
    } else {
      setStake(str.substring(0, str.length - 1));
    }
  };

  const isDisabled = () => {
    if (stake > 0) {
      return false;
    } else {
      return true;
    }
  };

  const placeBet = async () => {
    setLoaderNew(true);
    if (parlyBet?.length >= 3) {
      setLoader(true);
      let filtered = parlyBet?.filter((res) => res?.closed);
      if (filtered?.length > 0) {
        setIsBetPlaced("errorParly");
        setBetPlacedCheck(true);
        setMessageBox(true);
        setLoader(false);
        setBetSelectionObj({
          ...betSelectionObj,
          message: `Market are Closed [${filtered?.map((res, index) => {
            return `${res?.eventId},`;
          })}]`,
        });
        setLoaderNew(false);
      } else {
        const date = moment(new Date()).format("YYYY-MM-DD, h:mm:ss");
        let resultArray = parlyBet?.map((res) => {
          return {
            eventId: res?.newEventId,
            marketId: res?.marketId,
            fancySelectionId: res?.sportsBookSelectionObject?.id,
            selectionId: res?.id,
            betPlaceTime: date,
            fancyName: res?.marketName,
            bhav: res?.sportsBookSelectionObject?.odds,
            betType: "back",
            runnerName: res?.sportsBookSelectionObject?.selectionName,
            apiSiteSelectionId:
              res?.sportsBookSelectionObject?.apiSiteSelectionId,
            betPlaceType: "premiumFancy",
          };
        });
        let obj = {
          betPlaceTime: date,
          overallOdds: totalOdds,
          totalAmount: stake,
          bets: resultArray,
        };
        const { status, data: response_users } = await apiPost(
          apiPath.multiEventPlaceBet,
          obj
        );
        if (status === 200) {
          if (response_users?.success) {
            setIsBetPlaced("placedParly");
            setBetPlacedCheck(true);
            setMessageBox(true);
            setBetSelectionObj({
              ...betSelectionObj,
              message: response_users.message,
            });
            setLoader(false);
            amounutRefresh();
            setLoaderNew(false);
          } else {
            setIsBetPlaced("errorParly");
            setBetPlacedCheck(true);
            setMessageBox(true);
            setLoader(false);
            setBetSelectionObj({
              ...betSelectionObj,
              message: response_users.message,
            });
            setLoaderNew(false);
          }
        } else {
          setIsBetPlaced("errorParly");
          setBetPlacedCheck(true);
          setMessageBox(true);
          setLoader(false);
          setBetSelectionObj({
            ...betSelectionObj,
            message: response_users.message,
          });
          setLoaderNew(false);
        }
      }
    } else {
      setIsBetPlaced("errorParly");
      setBetPlacedCheck(true);
      setMessageBox(true);
      setLoader(false);
      setBetSelectionObj({
        ...betSelectionObj,
        message: "Please select min 3 parly bet",
      });
      setLoaderNew(false);
    }
  };

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        setShowParlyBet(false);
      }}
    >
      <div
        disabled={loaderNew}
        style={{ pointerEvents: loaderNew ? "none" : "" }}
        className="market-depth-modal"
      >
        <div
          style={{ background: "white", color: "black" }}
          className="market-title"
        >
          <h4>Bet Slip</h4>
          <Button
            onClick={() => setShowParlyBet(false)}
            style={{
              position: "absolute",
              top: "0",
              left: "90%",
              background: "transparent",
              color: "black",
              paddingTop: "10px",
              border: "none",
            }}
          >
            <RxCross2 size={20} />
          </Button>
        </div>

        <div class="">
          <div class="listing-parly-outer-main">
            {parlyBet?.map((res, index) => {
              return (
                <div class="listing-parly-outer">
                  <div className="listing-parly">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        opacity: res?.closed ? "0.6" : "1",
                        paddingTop: ".5rem!important",
                        paddingTottom: ".5rem!important",
                        paddingRight: ".25rem!important",
                        paddingLeft: ".25rem!important",
                      }}
                    >
                      {res?.closed && (
                        <span
                          style={{
                            marginRight: "8px",
                          }}
                          class="my-4px bg-black-600 leading-5 py-1 mr-2 text-black-300 px-2 rounded font-bold text-13"
                        >
                          Closed
                        </span>
                      )}
                      <span
                        style={{
                          marginRight: "8px",
                        }}
                      >
                        {obj.betCheckObj[res?.eventType]}
                      </span>
                      <BiSolidRightArrow
                        style={{
                          marginRight: "8px",
                        }}
                        color="rgb(90,94,98)"
                      />
                      <span style={{ fontWeight: "700" }}>
                        {res?.eventName || res?.marketName}
                      </span>
                    </div>
                    <MdDeleteForever
                      onClick={() => removeSlip(res?.newEventId)}
                      size={22}
                      style={{ width: "10%" }}
                      color="rgb(139,160,174)"
                    />
                  </div>
                  {!res?.closed && (
                    <div
                      style={{
                        background: "rgb(238,246,251)",
                        paddingBottom: "0 !important",
                      }}
                      class="listing-parly-slip"
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div style={{ fontWeight: 800 }}>
                          {res?.sportsBookSelectionObject?.selectionName}
                        </div>
                        <div
                          title={res?.sportsBookSelectionObject?.odds}
                          id={"parly_" + index}
                          class={`odds-parly-button ${
                            Number(
                              document
                                .getElementById("parly_" + index)
                                ?.title?.trim()
                            ) !== res?.sportsBookSelectionObject?.odds
                              ? "spark-parly"
                              : ""
                          }`}
                        >
                          {res?.sportsBookSelectionObject?.odds}
                        </div>
                      </div>
                      <span class="text-black">{res?.marketName}</span>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
          <div
            style={{
              borderTop: "1px solid #D4E0E5",
              borderBottom: "1px solid #D4E0E5",
              paddingRight: ".5rem ",
              paddingLeft: ".5rem",
            }}
          >
            <div className="total-odds-block">
              <div class="total-odds-block-left">
                <span
                  style={{ fontWeight: 700, marginRight: "8px" }}
                  class="mr-2 text-13"
                >
                  Total Odds
                </span>
                <span
                  style={{
                    marginRight: "8px",
                    color: "#c69700",
                    fontWeight: 700,
                  }}
                  class="mr-2 text-13"
                >
                  @{parlyBet?.length > 0 ? (totalOdds == 1 ? 0 : totalOdds) : 0}
                </span>
                <IoIosArrowForward color="green" />
                <span
                  style={{ color: "green" }}
                  // class="text-10 text-parlay-up font-bold"
                >
                  {totalOdds > 0 && stake > 0
                    ? ((totalOdds - 1) * stake)?.toFixed(2)
                    : 0}
                </span>
              </div>
              <div class="total-odds-block-left">
                <span
                  style={{ marginRight: "10px", fontWeight: "700" }}
                  class="text-13"
                >
                  Stake
                </span>
                <input
                  style={{
                    width: "80px",
                    borderRadius: "3px",
                    border: "1px solid black",
                    display: "flex",
                    justifyContent: "center",
                    textAlign: "center",
                    fontWeight: 700,
                    height: "6px",
                  }}
                  onClick={() => setShowDial(true)}
                  value={stake}
                />
              </div>
            </div>

            {showDial && (
              <>
                <div className="fix-bet-price">
                  <div className="fix-bet-btn">
                    {(profileData?.stake?.length > 0
                      ? profileData?.stake?.sort(function (a, b) {
                          return a - b;
                        })
                      : numberArray
                    )
                      ?.slice(0, 4)
                      ?.map((item) => {
                        return (
                          <Button
                            onClick={() => {
                              handelNumberChange(item);
                            }}
                            className="bg-yellow btn"
                          >
                            + {item}
                          </Button>
                        );
                      })}
                  </div>
                  {/* <div>
                    <Button
                      className="fix-bet-btn-setting-button"
                      onClick={() => handleStackSetting()}
                    >
                      <AiFillSetting className="fs-4" />
                    </Button>
                  </div> */}
                </div>
                <div className="betting-numbers">
                  <div className="bet-numeric">
                    {numberArrayCount?.map((res) => {
                      if (res == "img") {
                        return (
                          <Button
                            onClick={() => {
                              clear();
                            }}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                            className="clear-image"
                          >
                            <img
                              src={
                                process.env.REACT_APP_IMAGE_URL +
                                "/assets/images/cross.png"
                              }
                              alt=""
                            />
                          </Button>
                        );
                      } else {
                        return (
                          <Button
                            onClick={() => handelDialPad(res)}
                            className="btn"
                          >
                            {res}
                          </Button>
                        );
                      }
                    })}
                  </div>
                  <div className="cross-bet">
                    <Button
                      onClick={() => {
                        setShowDial(false);
                      }}
                      style={{
                        height: "100%",
                        width: "100%",
                      }}
                      // style={{ fontWeight: 600 }}
                    >
                      OK
                    </Button>
                  </div>
                </div>
              </>
            )}
          </div>
          <div
            style={{
              borderBottom: "1px solid #D4E0E5",
            }}
            class="item3 px-2 py-2 total-stake-last"
          >
            <div class="py-1">
              <div>
                <span style={{ fontSize: "1.2857142857142858rem" }}>
                  Total Stakes{" "}
                  <span style={{ fontWeight: "700" }}>{stake}</span>
                </span>
              </div>
              <div class="text-base">
                <span style={{ marginRight: "4px" }}>Max Profit</span>
                <span style={{ fontWeight: "700" }}>
                  {totalOdds > 0 && stake > 0
                    ? ((totalOdds - 1) * stake)?.toFixed(2)
                    : 0}
                </span>
              </div>
            </div>
            <button
              onClick={() => placeBet()}
              style={{
                background: "#ffc800",
                border: "none",
                borderRadius: "20px",
                fontWeight: "700",
                width: "12.928571428571429rem",
              }}
              disabled={loaderNew ? true : isDisabled()}
              class="btn-normal newPlaceButton bg-button-normal"
            >
              Place Bet
            </button>
          </div>
          <div className="accept-odds">
            <div className="accept-odds">
              <input
                style={{ marginRight: "5px" }}
                id="accept-any-odds"
                type="checkbox"
              />
              <span>Accept any odds</span>
            </div>
            <div class="flex items-center space-x-1">
              <mark class="mark">Max</mark>
            </div>
          </div>
        </div>
        {showStackSetting && (
          <StackSetting handleStackSetting={handleStackSetting} />
        )}
        {loaderNew && (
          <div className="loading-bet">
            <img src={loading} />
          </div>
        )}
      </div>
    </OutsideClickHandler>
  );
};

export default BetSlipParly;
