import { isEmpty } from "lodash";
import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AuthProvider from "../context/AuthContext";
const Footer = () => {
  const navigate = useNavigate();
  let { user, setGameFooter, gameFooter } = useContext(AuthProvider);
  const [select, setSelect] = useState("home");
  const handleSelect = (p) => {
    setSelect(p);
  };
  return (
    <div>
      {gameFooter && (
        <div className="lobby" data-v-2a94eb60="" data-v-5f5b6ef7="">
          <div className="gmnheader" data-v-302cf84b="" data-v-2a94eb60="">
            <div className="header__img" data-v-302cf84b="">
              <div className="header__img__title" data-v-302cf84b="">
                <picture
                  className="pictureClass"
                  data-v-28fc9a52=""
                  data-v-302cf84b=""
                >
                  <img
                    className="icon-promote"
                    src={
                      process.env.REACT_APP_IMAGE_URL +
                      "/assets/images/icon-title.webp"
                    }
                    alt=""
                  // src={process.env.REACT_APP_IMAGE_URL + '/yourPathHere.jpg' }
                  />
                </picture>
                <span className="header__number" data-v-302cf84b="">
                  2
                </span>
              </div>
            </div>

            <div className="header__close" data-v-302cf84b="">
              <img
                className="icon-promote"
                src={
                  process.env.REACT_APP_IMAGE_URL +
                  "/assets/images/icon-close.webp"
                }
                onClick={() => setGameFooter(false)}
                alt=""
              // src={process.env.REACT_APP_IMAGE_URL + '/yourPathHere.jpg' }
              />
            </div>
          </div>
        </div>
      )}
      <nav>
        <ul
          className=""
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <li id="sports">
            <Link
              to="/Dsports"
              neua="Sports"
              className={select == "sports" ? "ui-link active" : "ui-link"}
              onClick={() => handleSelect("sports")}
            >
              <img
                className="icon-sports"
                src={
                  process.env.REACT_APP_IMAGE_URL +
                  "/assets/images/home/transparent.gif"
                }
                alt=""
              // src={process.env.REACT_APP_IMAGE_URL + '/yourPathHere.jpg' }
              />
              Sports
            </Link>
          </li>

          <li id="inPlay">
            <Link
              to="/Dinplay"
              neua="In-Play"
              className={select == "inplay" ? "ui-link active" : "ui-link"}
              onClick={() => handleSelect("inplay")}
            >
              <img
                className="icon-inplay"
                src={
                  process.env.REACT_APP_IMAGE_URL +
                  "/assets/images/home/transparent.gif"
                }
                alt=""
              />
              In-Play
            </Link>
          </li>

          <li
            id="home"
            className={select == "home" ? "main-nav active" : "main-nav"}
            onClick={() => handleSelect("home")}
          >
            <Link to="/Duserhome" neua="Home">
              <img
                className="icon-home"
                src={
                  process.env.REACT_APP_IMAGE_URL +
                  "/assets/images/home/transparent.gif"
                }
                alt=""
              />
              Home
            </Link>
          </li>

          <li id="multiMarket">
            <Link
              to="/DmultiMarket"
              neua="Multi Markets"
              className={select == "multi" ? "ui-link active" : "ui-link"}
              onClick={() => handleSelect("multi")}
            >
              <img
                className="icon-pin"
                src={
                  process.env.REACT_APP_IMAGE_URL +
                  "/assets/images/home/transparent.gif"
                }
                alt=""
              />
              Multi Markets
            </Link>
          </li>

          <li id="account">
            <Link
              to={user ? "/Maccount" : "/Mlogin"}
              className={select == "account" ? "ui-link active" : "ui-link"}
              onClick={() => handleSelect("account")}
            >
              <img
                className="icon-account"
                src={
                  process.env.REACT_APP_IMAGE_URL +
                  "/assets/images/home/transparent.gif"
                }
                alt=""
              />
              Account
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Footer;
