import React from "react";
import { useContext } from "react";
import AuthProvider from "../context/AuthContext";

const WelcomLoader = () => {
    let { welcomeLoader } = useContext(AuthProvider);
    return (
        <div
            className="loading-overlay"
            style={{ display: welcomeLoader ? "flex" : "none" }}
            id="loading"
        >
            <div className="loading-wrap">
                <div className="loading">
                    <div></div>
                    <div></div>
                </div>
                <p>Welcome...</p>
            </div>
        </div>
    );
};

export default WelcomLoader;
