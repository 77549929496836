import { isEmpty } from "lodash";
import React, { useContext, useEffect, useRef } from "react";
import { useState } from "react";
import BetSlipContext from "../../context/BetSlipContext";
import Keyboard from "./Keyboard";
import AuthContext from "../../context/AuthContext";
import secureLocalStorage from "react-secure-storage";
const PreminumFancyNew = ({
  data,
  betType,
  setSlipObj,
  slipObj,
  details,
  profileData,
  user,
  resetSelection,
  key,
  messaageBox,
  setPremiumFancy,
  preminumFancyTab,
  activeSportBook,
  setActiveSportBook,
}) => {
  const [selected, setSelected] = useState("");
  const { parly, parlyBet, setParlyBet } = useContext(AuthContext);
  let { betSelectionObj, setBetSelectionObj, defaultStake, defaultOdds } =
    useContext(BetSlipContext);
  const [amountRange, setAmountRange] = useState({
    min: 1,
    max: 100,
    oddsLimit: 1000,
  });
  useEffect(() => {
    if (!isEmpty(user)) {
      let obj = profileData?.matchSetting?.find(
        (res) =>
          res?.type == "sportBook" &&
          (profileData?.arrayCheck == "details"
            ? true
            : res?.sportType == details?.gameType)
      );
      if (!isEmpty(obj)) {
        setAmountRange({
          ...obj,
          min: Number(obj?.minAmount),
          max: Number(obj?.maxAmount),
          oddsLimit: obj.oddsLimit ? obj.oddsLimit : 0,
        });
      }
    }
  }, [details]);
  const myRef = useRef(null);
  const [id, setId] = useState("");
  const [id2, setId2] = useState("");
  const handleScroll = () => {
    setId("");
    setId2("");
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  useEffect(() => {
    if (!isEmpty(id)) {
      myRef.current.scrollIntoView(true);
    }
  }, [id]);
  const [innerId, setInnerId] = useState("");
  useEffect(() => {
    if (key == "Fancy Bet") {
      setInnerId("");
      setSelected("");
    }
  }, [key]);
  useEffect(() => {
    setPremiumFancy((current) => {
      return current?.map((res, index) => {
        return { ...res, check: index < 5 ? true : false };
      });
    });
  }, []);

  useEffect(() => {
    if (details?.gameType == "cricket") {
      data.sort(function (a, b) {
        if (a.marketName === "Will there be a tie") {
          return -1;
        }
        return 0;
      });
      data.sort(function (a, b) {
        if (a.marketName === "Winner (incl. super over)") {
          return -1;
        }
        return 0;
      });
    }
  }, [details]);

  const [newActive, setNewActive] = useState([]);
  useEffect(() => {
    setNewActive(
      data?.slice(0, 5)?.map((po) => {
        return po?.id;
      })
    );
  }, []);

  const sportBookCheck = (parlyBet, detail, res) => {
    if (isEmpty(user)) {
      return "";
    } else {
      let find = parlyBet?.find((res) => {
        return res?.newEventId == detail?.eventId;
      });
      if (!isEmpty(find)) {
        return find?.sportsBookSelectionObject?.id == res?.id
          ? "active-sportBook rounded-b-md"
          : "rounded-b-md";
      } else {
        return "rounded-b-md";
      }
    }
  };
  return data?.length > 0 ? (
    <>
      <div
        id="fancyBetTabWrap"
        className="special_bets-tab-wrap ps ps--theme_default ps--active-x"
      >
        <ul className="special_bets-tab" style={{ overflowX: "scroll" }}>
          {preminumFancyTab?.length > 0 &&
            preminumFancyTab?.map((res) => {
              return (
                <li>
                  <a href="javascript:void(0)">{res.name}</a>
                </li>
              );
            })}
        </ul>
      </div>
      {data?.length > 0 &&
        data?.map((item, index) => {
          if (item?.marketStatus == 1 || item?.marketStatus == 0) {
            if (item?.sportsBookSelection?.length > 0) {
              return (
                <div
                  id="sportsBookMarket_2_2558118_265869284"
                  className="bets-wrap sports-book new-premimum-fancy"
                  key={index + 1}
                >
                  <h4
                    ref={item?.id == id ? myRef : null}
                    onClick={() => {
                      setSelected(item?.id);
                      setActiveSportBook((current) => {
                        if (newActive?.includes(item?.id)) {
                          setNewActive((prev) => {
                            return prev?.filter((res) => {
                              return res !== item?.id;
                            });
                          });
                        } else {
                          if (current?.includes(item?.id)) {
                            return current?.filter((res) => {
                              return res !== item?.id;
                            });
                          } else {
                            if (current?.length > 0) {
                              return [...current, item?.id];
                            } else {
                              return [item?.id];
                            }
                          }
                        }
                      });
                      // setPremiumFancy((current) => {
                      //   return current?.map((res) => {
                      //     if (res?.id == item?.id) {
                      //       return { ...res, check: !item.check };
                      //     } else {
                      //       return res;
                      //     }
                      //   });
                      // });
                    }}
                  >
                    {/* <a
                      id="multiMarketPin"
                      className="pin-off"
                      title="Add to Multi Markets"
                    ></a> */}
                    <span id="marketName" className="to-collapse">
                      {item?.marketName}
                    </span>
                    <div className="fancy_info-popup">
                      <dl>
                        <dt>Min / Max</dt>
                        <dd>100.00 / 10000.00</dd>
                      </dl>
                      <a className="close">Close</a>
                    </div>
                    {![
                      ...newActive,
                      ...(activeSportBook?.length > 0 ? activeSportBook : []),
                    ]?.includes(item?.id) ? (
                      <span className="up-down-arrow">
                        <img
                          src={
                            process.env.REACT_APP_IMAGE_URL +
                            "/assets/images/down-arrow-white.png"
                          }
                          alt=""
                        />
                      </span>
                    ) : (
                      <span className="up-down-arrow">
                        <img
                          style={{ transform: "rotate(180deg)" }}
                          src={
                            process.env.REACT_APP_IMAGE_URL +
                            "/assets/images/down-arrow-white.png"
                          }
                          alt=""
                        />
                      </span>
                    )}
                  </h4>
                  <div className="text-dark daka-score-sec ">
                    {[
                      ...newActive,
                      ...(activeSportBook?.length > 0 ? activeSportBook : []),
                    ]?.includes(item?.id) &&
                      item?.sportsBookSelection?.map((res) => {
                        let newPosition = "";
                        if (
                          betSelectionObj?.priceToogle &&
                          betSelectionObj?.betType == "premiumFancy" &&
                          innerId == slipObj?.selectId &&
                          item?.id == selected
                        ) {
                          let backProfit =
                            (Number(betSelectionObj?.oddsSelect) - 1) *
                            Number(betSelectionObj?.bidPrice);
                          let backLoss = Number(betSelectionObj?.bidPrice);
                          if (res.id === slipObj?.selectId) {
                            newPosition =
                              Math.abs(res?.position) > 0
                                ? Number(backProfit) + Number(res.position)
                                : Number(backProfit);
                          } else {
                            newPosition =
                              Math.abs(res?.position) > 0
                                ? Number(backLoss) - Number(res?.position)
                                : -Number(backLoss);
                          }
                        }
                        let condition =
                          betSelectionObj?.priceToogle &&
                          betSelectionObj?.betType == "premiumFancy" &&
                          innerId == slipObj?.selectId &&
                          item?.id == selected &&
                          Number(betSelectionObj?.bidPrice) > 0
                            ? true
                            : false;
                        return (
                          <>
                            <div
                              style={{
                                backgroundColor: process.env
                                  .REACT_APP_PREMIUM_FANCY_BOX_COLOR
                                  ? process.env
                                      .REACT_APP_PREMIUM_FANCY_BOX_COLOR
                                  : "",
                              }}
                              className={
                                parly
                                  ? sportBookCheck(parlyBet, details, res)
                                  : slipObj.selectId == res.id
                                  ? `back-active rounded-b-md ${
                                      Number(
                                        document.getElementById("odds1")?.name
                                      ) !== res?.odds && "fade-in-out"
                                    }`
                                  : `rounded-b-md ${
                                      Number(
                                        document.getElementById("odds1")?.name
                                      ) !== res?.odds && "fade-in-out"
                                    }`
                              }
                              onClick={() => {
                                if (
                                  details?.sport_setting?.status == "active"
                                ) {
                                  if (details.premiumFancy == "on") {
                                    if (parly) {
                                      setParlyBet((current) => {
                                        let newObj = {
                                          ...item,
                                          sportsBookSelectionObject: res,
                                          selectObj: res?.id,
                                          newEventId: details?.eventId,
                                          eventName: details?.eventName,
                                          marketId: details?.marketId,
                                        };
                                        if (current?.length > 0) {
                                          if (
                                            current?.filter((temp) => {
                                              return (
                                                temp?.newEventId ==
                                                details?.eventId
                                              );
                                            })?.length > 0
                                          ) {
                                            if (
                                              current?.filter((temp) => {
                                                return (
                                                  temp?.newEventId !==
                                                  details?.eventId
                                                );
                                              })?.length > 0
                                            ) {
                                              secureLocalStorage.setItem(
                                                "parlyBet",
                                                [
                                                  ...current?.filter((temp) => {
                                                    return (
                                                      temp?.newEventId !==
                                                      details?.eventId
                                                    );
                                                  }),
                                                  newObj,
                                                ]
                                              );
                                              return [
                                                ...current?.filter((temp) => {
                                                  return (
                                                    temp?.newEventId !==
                                                    details?.eventId
                                                  );
                                                }),
                                                newObj,
                                              ];
                                            } else {
                                              secureLocalStorage.setItem(
                                                "parlyBet",
                                                [newObj]
                                              );
                                              return [newObj];
                                            }
                                          } else {
                                            secureLocalStorage.setItem(
                                              "parlyBet",
                                              [...current, newObj]
                                            );
                                            return [...current, newObj];
                                          }
                                        } else {
                                          secureLocalStorage.setItem(
                                            "parlyBet",
                                            [newObj]
                                          );
                                          return [newObj];
                                        }
                                      });
                                    } else {
                                      if (!messaageBox) {
                                        if (
                                          betSelectionObj.betType !== betType
                                        ) {
                                          resetSelection(
                                            betSelectionObj.betType
                                          );
                                        }
                                        setSelected(item?.id);
                                        setBetSelectionObj((prev) => ({
                                          ...betSelectionObj,
                                          betType: betType,
                                          oddsSelect: res?.odds,
                                          bidPrice:
                                            defaultStake == 0
                                              ? ""
                                              : defaultStake,
                                          priceToogle: true,
                                          layorback: "back",
                                          inputType: "price",
                                          expectany: defaultOdds,
                                          minAmount: amountRange.min,
                                          maxAmount: amountRange.max,
                                        }));
                                        setId(item?.id);
                                        setInnerId(res?.id);
                                        setSlipObj({
                                          ...item,
                                          selectId: res.id,
                                          newMarketId: item.id,
                                          obj: res,
                                        });
                                      }
                                    }
                                  }
                                }
                              }}
                            >
                              {/* {details.premiumFancy == "off" ||
                            details?.sport_setting?.status == "inActive" || item.marketStatus == 2 ? (
                              <div className="suspend-case">
                                <strong>Suspend</strong>
                              </div>
                            ) : (
                              ""
                            )} */}

                              {/* {details?.premiumFancy == "off" ||
                          details?.sport_setting?.status == "inActive" ? (
                            <div className="suspend-case">
                              <strong>Suspended</strong>
                            </div>
                          ) : item?.marketStatus == 1 ? (
                            ""
                          ) : (
                            <div className="suspend-case">
                              <strong>
                                {item?.marketStatus == 9
                                  ? "Ball Running"
                                  : item?.marketStatus == 2
                                  ? "In Active"
                                  : item?.marketStatus == 3
                                  ? "Suspended"
                                  : item?.marketStatus == 4
                                  ? "Closed"
                                  : ""}
                              </strong>
                            </div>
                          )} */}

                              <h5>{res.selectionName}</h5>
                              <div style={{ display: `flex` }}>
                                {(Math.abs(res.position) > 0 ||
                                  condition) && (
                                    <div   className={`${
                                      res.position > 0 ? "color-green" : ""
                                    } dolor_tag`}>
                                      {Math.abs(res.position) > 0 &&
                                      Math.abs(res?.position)?.toFixed(2)}
                                      {!parly &&
                                        condition &&
                                        Math.abs(newPosition) > 0 &&
                                        betSelectionObj?.priceToogle &&
                                        betSelectionObj?.betType == betType && (
                                          <>
                                          <div
                                            className={`${
                                              newPosition > 0 ? "color-green" : ""
                                            } dolor_tag`}
                                            style={{ marginLeft: "5px" }}
                                          >
                                            <span className="right-arrow">
                                              <img
                                                src={
                                                  process.env
                                                    .REACT_APP_IMAGE_URL +
                                                  "/assets/images/down-arrow.png"
                                                }
                                                alt=""
                                              />
                                            </span>
                                            $(
                                            {Math.abs(newPosition)?.toFixed(2)})
                                            </div>
                                          </>
                                        )}
                                    </div>
                                  )}
                                {/* {!parly &&
                                  condition &&
                                  Math.abs(newPosition) > 0 &&
                                  betSelectionObj?.priceToogle &&
                                  betSelectionObj?.betType == betType && (
                                    <div
                                      className="dolor_tag"
                                      style={{ marginLeft: "5px" }}
                                    >
                                      <span className="right-arrow">
                                        <img
                                          src={
                                            process.env.REACT_APP_IMAGE_URL +
                                            "/assets/images/down-arrow.png"
                                          }
                                          alt=""
                                        />
                                      </span>
                                      $({Math.abs(newPosition)?.toFixed(2)})
                                    </div>
                                  )} */}
                                <p id={"odds1"} name={res?.odds}>
                                  {res?.odds}
                                </p>
                              </div>
                            </div>
                          </>
                        );
                      })}
                  </div>
                  {betSelectionObj?.priceToogle &&
                    betSelectionObj?.betType == "premiumFancy" &&
                    innerId == slipObj?.selectId &&
                    item?.id == selected && (
                      <Keyboard
                        setSlipObj={setSlipObj}
                        details={details}
                        slipObj={slipObj}
                        amountRange={amountRange}
                        betType={betType}
                        resetSelection={resetSelection}
                      />
                    )}
                </div>
              );
            }
          }
        })}
    </>
  ) : (
    <div
      id="sportsBookNoBet"
      className="bets-wrap"
      style={{ display: "block" }}
    >
      <p className="no-game">Sports Book has no market</p>
    </div>
  );
};

export default PreminumFancyNew;
